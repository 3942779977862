import key from './key'
import { Resource } from '@ekuaibao/fetch'
import { showMessage } from '@ekuaibao/show-util'
const tpp = new Resource('/api/tpp/v2')
const tpp_yeego = new Resource('/api/tpp/v2/control/yeego')
const datalink = new Resource('/api/v2/datalink')
const permission = new Resource('/api/v2/datalink/platform')
const platformUpdate = new Resource('/api/v2/datalink/platform/updatePlatformAdminIds')
const certification = new Resource('/api/v2/certification')
const dingtalk = new Resource('/api/dingtalk/v2')
const didiDown = new Resource('/api/tpp/v2/orders/export')
const travelManagement = new Resource('/api/tpp/v2/travelManagement')
const synTravelOrder = new Resource('/api/tpp/v2/travelManagement/order')
const syncAliTrip = new Resource('/api/tpp/v2/alitrip')
const gaeaJwt = new Resource('/api/engine/intent/gaea/jwt')
const staffs = new Resource('/api/v1/organization/staffs')
const ldap = new Resource('/api/ldap')
const redirectUrl = new Resource('/api/tpp/v2/gaea/redirectUrl')
const dimensions = new Resource('/api/v1/basedata/dimensions')
const afpAction = new Resource('/api/v1/aifapiao')
const powers = new Resource('/api/charge/powers')
const widgetConfig = new Resource('/api/v1/flow/smallComponentConfig')
const nbbank = new Resource('/api/nbbank/v1')
const mail = new Resource('/api/email/config')
const mailApprove = new Resource('/api/flow/v1/emailApprove/config')
const delegate = new Resource('/api/v1/organization/delegateSet')
const authorizedDelegate = new Resource('/api/v1/organization/delegate')
const authorizedDelegateBatch = new Resource('/api/v1/organization/delegate/optConfig')
const subsidyAction = new Resource('/api/v1/requisition/subsidy')
const feeTypeRes = new Resource('/api/v1/form/feeTypes')
const tripTypes = new Resource('/api/trip/v2/type')
const specificationVersions = new Resource('/api/form/v1/specificationVersions')
const specifications = new Resource('/api/form/v2/specifications/feeType')
const doucmentQuery = new Resource('/api/flow/v1/queryRule')
const localConfig = new Resource('/api/v1/organization/corporations')
const layoutConfig = new Resource('/api/v1/flow/layoutConfig')
const autoGenerationFeeDetail = new Resource('/api/flow/v1/autoGenerationFeeDetail')
const geelyTripConfig = new Resource('/api/tpp/v2/travel')
const referables = new Resource('/api/flow/v2/referables')
const eBussCardInfo = new Resource('/api/v2/withNotes/generate/config/eBussCard')
import { QuerySelect } from 'ekbc-query-builder'
const association = new Resource('/api/v1/association/group')
const getSpecificationListByType = new Resource(
  '/api/form/v2/specifications/getSpecificationVersionListByType',
)
const autoCreateConfig = new Resource('/api/v1/organization/delegateAuto')
const staffRes = new Resource('/api/v1/organization/staffs')

const extensionCenterConfigRes = new Resource('/api/v1/flow/extensionCenterConfig')

const departmentsCheck = new Resource("/api/checking/tobacco/v1/charge")
const eaiweb = new Resource('/api/extension/eaiweb')
const documentVoid = new Resource('/api/flow/v1/nullifyRule')

import { app as api } from '@ekuaibao/whispered'
import parseQuery2OrderSelect from '@ekuaibao/lib/lib/ParseQuery2OrderSelect'



export const GrantResource = new Resource('/api/pay/v2/api/corporationGrant')
export const AutoCalcResource = new Resource('/api/flow/v1/autoRule')

export function setTmOrderNotice(param) {
  return tpp.PUT('/travelManagement/setTravelManagementConfig', param)
}

export function delegateRangeObj(param) {
  return {
    type: key.DELEGATE_RANGE_OBJ,
    payload: delegate.GET('/$delegateType', param),
  }
}

export function delegateRangeEdit(params) {
  return {
    type: key.DELEGATE_RANGE_EDIT,
    payload: delegate.POST('/save', params),
  }
}

export function delegateRuleList(param) {
  return {
    type: key.DELEGATE_RULE_LIST,
    payload: delegate.GET('/rule/$delegateType', param),
  }
}

export function delegateRuleEdit(params) {
  return {
    type: key.DELEGATE_RULE_EDIT,
    payload: delegate.POST('/rule/save', params),
  }
}

export function delegateRuleModify(params) {
  return {
    type: key.DELEGATE_RULE_MODIFIY,
    payload: delegate.PUT('/rule/save', params),
  }
}

export function delegateRuleDelete(param) {
  return {
    type: key.DELEGATE_RULE_DEL,
    payload: delegate.DELETE('/rule/$ruleId', param),
  }
}
// authorizedDelegate委托授权强弱管控
// 根据类型获取人员列表
export function GetDelegateListByType(type) {
  return {
    type: key.GET_DELEGATE_LIST_BY_TYPE,
    payload: authorizedDelegate.GET('/byStaffId/$type', { type, admin: true }),
  }
}
// 管理员增加委托授权
export function SetDelegateConfig(params) {
  return {
    type: key.SET_DELEGATE_CONFIG,
    payload: authorizedDelegate.POST(`?admin=${true}`, params),
  }
}
// 管理员删除委托授权
export function DeleteDelagateItem(params) {
  return {
    type: key.DELETE_DELAGATE_ITEM,
    payload: authorizedDelegate.DELETE(`/$delegateType/$rowId?admin=${true}`, params),
  }
}
// 管理员修改委托授权
export function modifyDelegateConfig(params) {
  return {
    type: key.MODIFY_DELEGATE_CONFIG,
    payload: authorizedDelegate.PUT(`/$delegateType/$rowId?admin=${true}`, params),
  }
}
// 更新是否只有管理员可编辑
export function updateDelegateConfig(params) {
  return {
    type: key.UPDATE_DELEGATE_CONFIG,
    payload: authorizedDelegate.PUT(`/updateDelegateConfig/${params.delegateType}`, params),
  }
}
// 获取管理员是否可编辑
export function getDelegateConfig() {
  return {
    type: key.GET_DELEGATE_CONFIG,
    payload: authorizedDelegate.GET(`/getDelegateConfig`),
  }
}
// authorizedDelegate委托授权批量操作
// 根据类型获取人员列表
export function GetDelegateListByTypeBatch(type) {
  return {
    type: key.GET_DELEGATE_LIST_BY_TYPE_BATCH,
    payload: authorizedDelegateBatch.GET('/$type', { type, admin: true }),
  }
}
// 管理员增加委托授权
export function SetDelegateConfigBatch(params) {
  return {
    type: key.SET_DELEGATE_CONFIG_BATCH,
    payload: authorizedDelegateBatch.POST(`?admin=${true}`, params),
  }
}
// 管理员删除委托授权
export function DeleteDelagateItemBatch(params) {
  return {
    type: key.DELETE_DELAGATE_ITEM_BATCH,
    payload: authorizedDelegateBatch.DELETE(`/$delegateType/$rowId?admin=${true}`, params),
  }
}
// 管理员修改委托授权
export function modifyDelegateConfigBatch(params) {
  return {
    type: key.MODIFY_DELEGATE_CONFIG_BATCH,
    payload: authorizedDelegateBatch.PUT(`/$id?admin=${true}`, params),
  }
}
//yeego
export function getYeegoBindingInfo() {
  return {
    type: key.GET_YEEGO_BINDING_INFO,
    payload: tpp.GET(`/platforms/$id`, {
      id: 'YEEGO',
    }),
  }
}

export function bindCorp(value) {
  return {
    type: key.BIND_CORP,
    payload: tpp.POST(`/corporationBindings/yeego/init`, {
      ...value,
    }),
  }
}

export function corporationBinding() {
  return {
    type: key.GET_CORPORATION_BINDING,
    payload: tpp.GET(`/corporationBindings/corporationBinding`, {
      platform: 'DIDI',
    }),
  }
}
export function getLdapConfig() {
  return {
    type: key.LDAP_GET_LOGIN_CONFIG,
    payload: ldap.GET(`/config`),
  }
}
export function setLdapConfig(params) {
  const { id } = params
  return {
    type: key.LDAP_UPDATE_LOGIN_CONFIG,
    payload: ldap.POST(`/config`, params),
  }
}
export function getSynStatus() {
  return {
    type: key.LDAP_GET_SYN_STATUS,
    payload: ldap.POST(`/status`),
  }
}
export function syncCarSystem() {
  // TODO 确认一下是什么类型的请求
  return {
    type: key.SYNC_CAR_SYSTEM,
    payload: tpp.GET(`/didi/config/syncRegulation`),
  }
}
export function didiFieldSettings(params) {
  return {
    type: key.CAR_SYSTEM_FIELD_SETTINGS,
    payload: tpp.PUT(`/didi/config/configRegulation`, params),
  }
}
export function getDidiFieldSettings() {
  return {
    type: key.GET_CAR_SYSTEM_FIELD_SETTINGS,
    payload: tpp.GET(`/didi/config/getConfigRegulation`),
  }
}
export function syncStaffByStaffIds(staffIds) {
  return {
    type: key.LDAP_SYNC_STAFF_BY_STAFF_ID,
    payload: ldap.PUT(`/sync/staff`, {
      staffIds: staffIds,
    }),
  }
}
export function syncDidiStaffByStaffIds(staffIds) {
  return {
    type: key.DIDI_SYNC_STAFF_BY_STAFF_ID,
    payload: tpp.PUT(`/travel/$platform/staff/sync`, {
      platform: 'DIDI',
      staffIds: staffIds,
    }),
  }
}
export function getLdapSynStaffList(data) {
  return {
    type: key.LDAP_GET_SYN_STAFF_LIST,
    payload: ldap.GET(`/sync/user/list`, data),
  }
}
export function ldapSyncStaff() {
  return {
    type: key.LDAP_SYNC_STAFF,
    payload: ldap.GET(`/sync`),
  }
}
export function getLdapLogs(params) {
  return {
    type: key.LDAP_GET_LOGS,
    payload: ldap.POST(`/logs`, params),
  }
}

export function getUnknownStaffs(params = {}) {
  params.bindingErrors = ['ACCOUNTUNKNOWN']
  params.platformId = 'YEEGO'
  params.join =
    'syncResults.staffBindings.staffId,staffId,/v1/organization/staffs?select=name,avatar,id'
  return {
    type: key.GET_UNKNOWN_STAFFS,
    payload: tpp.GET('/staffBindings/$platformId/[bindingErrors]', params),
  }
}

export function getWhiteListStaffs(params = {}) {
  params.join = 'staffId,staffId,/v1/organization/staffs?select=name,avatar,id'
  return {
    type: key.GET_WHITELISTE_STAFFS,
    payload: tpp_yeego.GET('/getWhiteListStaffBinding', params),
  }
}

export function saveWhiteListStaffs(params = {}) {
  return {
    type: key.SAVE_WHITELISTE_STAFFS,
    payload: tpp_yeego.PUT('/setWhiteListStaffBinding', params),
  }
}

export function saveControlCondition(params) {
  return {
    type: key.SAVE_CONTROLCONDITION,
    payload: tpp_yeego.POST('/setControlCondition', params),
  }
}

export function changeStatus(params) {
  return {
    type: key.CHANGE_STATUS,
    payload: tpp.PUT('/entries/$entryId', null, params),
  }
}

//didi
export function sychDiDiState(param) {
  return {
    type: key.SYCH_DIDI_STATE,
    payload: tpp.PUT('/staffBindings/sync/$platformId', param),
  }
}

export function getSychStaffResult(params) {
  const errorType = params.bindingErrors
  return {
    type: key.GET_SYCH_STAFF_RESULT,
    payload: tpp.GET('/staffBindings/$platformId/[bindingErrors]', params),
    errorType,
  }
}

export function getRegistrationInfo() {
  return {
    type: key.GET_REGISTRATION_INFO,
    payload: tpp.GET('/platformList'),
  }
}
export function getDidiRegistrationInfo() {
  return {
    type: key.GET_DIDI_REGISTRATION_INFO,
    payload: tpp.GET(`/travel/$platform/binding`, {
      platform: 'DIDI',
    }),
  }
}
export function syncDidiStaff() {
  return {
    type: key.SYNC_DIDI_STAFF,
    payload: tpp.PUT('/travel/$platform/staff/syncAll', {
      platform: 'DIDI',
    }),
  }
}
export function getDidiStaffSyncStatus() {
  return {
    type: key.GET_DIDI_STAFF_SYNC_STATUS,
    payload: tpp.GET('/travel/$platform/staff/syncResult', {
      platform: 'DIDI',
    }),
  }
}
export function getDidiSyncLogs(params) {
  return {
    type: key.GET_DIDI_SYNC_LOGS,
    payload: tpp.GET('/travel/$platform/staff/binding/list', {
      platform: 'DIDI',
      ...params,
    }),
  }
}

export function getAuthUrl(params) {
  return {
    type: key.GET_AUTH_URL,
    payload: tpp.GET('/corporationBindings/getBindingUrl/$corporationBindingId', params),
  }
}
export function getAuthUrlNew(params) {
  return {
    type: key.GET_AUTH_URL_NEW,
    payload: tpp.GET('/didi/config/getUrl/$corporationBindingId', params),
  }
}

export function sendMsg(params) {
  let url = `/staffBindings/remind/$platformId/$bindingError`
  if (params.staffsIds) {
    url = `/corporationBindings/remind/$platformId/$template/[staffsIds]`
  }
  return {
    type: key.SEND_MESSAGE,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then((messageCode) =>
        tpp.POST(url, params, {
          messageCode,
        }),
      )
      .catch((e) => Promise.reject(e)),
  }
}

export function getDiDiCaptcha(params) {
  return {
    type: key.GET_DIDI_CAPTCHA,
    payload: tpp.POST('/staffBindings/getCaptcha/$platformId', params),
  }
}

export function bindStaffDiDi(params) {
  const { platformId, ...others } = params

  return {
    type: key.BIND_STAFF_DIDI,
    payload: tpp.POST(
      '/staffBindings/bindingByPhone/$platformId',
      {
        platformId,
      },
      others,
    ),
  }
}

export function switchThirdParty(params) {
  return {
    type: key.SWITCH_THIRD_PARTY,
    payload: tpp.PUT(
      '/corporationBindings/$platformId',
      {
        platformId: params.platformId,
      },
      {
        active: params.active,
      },
    ),
  }
}

export function getOrderBaseProperties() {
  return {
    type: key.GET_ORDER_BASE_PROPERTIES,
    payload: tpp.GET('/propertySet'),
  }
}

export function searchOrdersData(params, qs) {
  const query = parseQuery2OrderSelect(params).select('flowId(...),...')
  return {
    type: key.SEARCH_DIDI_ORDERS_DATA,
    payload: tpp.POST('/orders/search/mgt', query.value(), qs),
  }
}

export function getDisplayFields() {
  return {
    type: key.GET_DISPLAY_FIELDS,
    payload: tpp.GET('/propertySet/displayFields/mgt'),
  }
}

export function remindReimburse(params) {
  return {
    type: key.REMIND_REIMBURSE,
    payload: api
      .invoke('@vendor:dd:message:code')
      .then((messageCode) =>
        tpp.POST('/orders/remind', params, {
          messageCode,
        }),
      )
      .catch((e) => Promise.reject(e)),
  }
}

export function getMappingRelation() {
  return {
    type: key.GET_MAPPING_RELATION,
    payload: tpp.GET('/mappingSet'),
  }
}

export function getAllOrderIds(params, qs) {
  const query = parseQuery2OrderSelect(params, true)
  return {
    type: key.GET_ALL_ORDER_IDS,
    payload: tpp.POST('/orders/search/ids', query.value(), qs),
  }
}

export function getTripDataLink(params) {
  return {
    type: key.GET_TRIP_DATALINK,
    payload: permission.GET('/type/$type', params),
  }
}
export function updateTripDataLink(data, done) {
  return {
    type: key.UPDATE_TRIP_DATALINK,
    payload: platformUpdate.PUT('/$id', data),
    done,
  }
}

export function getElecEntityList(params = {}) {
  params.join = 'platformId,platformId,/v2/datalink/platform'
  return {
    type: key.GET_ELEC_ENTITY_LIST,
    payload: datalink.GET('/entity/define', params),
  }
}

export function updateElecEntityList(params) {
  return {
    type: key.UPDATE_ELEC_ENTITY_LIST,
    payload: datalink.POST('/book/update', params),
  }
}

export function selectedElecEntityList() {
  return {
    type: key.SELECTED_ELEC_ENTITY_LIST,
    payload: datalink.GET('/book/select'),
  }
}

export function getSyncResult() {
  return {
    type: key.GET_PASSENGER,
    payload: datalink.GET(`/passenger/getSyncResult`),
  }
}
export function syncPassenger() {
  return {
    type: key.AYNC_PASSENGER,
    payload: datalink.PUT(`/passenger/sync`),
  }
}
export function getBuyerSyncResult() {
  return {
    type: key.GET_BUYER,
    payload: datalink.GET(`/buyer/result`),
  }
}
export function syncBuyer() {
  return {
    type: key.AYNC_BUYER,
    payload: datalink.PUT(`/buyer/sync`),
  }
}

export function getDiDiPersonalConfig() {
  return tpp.GET('/didi/didiPersonalSetting')
}

export function updateDiDiPersonalConfig(param) {
  return tpp.POST('/didi/didiPersonalSetting/$feeTypeId', param)
}

export function getDiDiPersonalUrl(param) {
  return tpp.GET('/didi/auth/$action', param)
}

export function checkDiDiPersonalAuthorized() {
  return {
    type: key.DIDI_PERSONAL_IS_AUTHORIZED,
    payload: tpp.GET('/didi/isAuthorized'),
  }
}

//获取发票认证配置
export function getCertificationConfig(params) {
  return {
    type: key.GET_CERTIFICATION_CONFIG,
    payload: certification.GET('/getConfig', params),
  }
}

//自动更新更新税号信息认证状态
export function updateCertTaxNumberInfo(params) {
  return {
    type: key.UPDATE_CERT_TAXNUMBER_INFO,
    payload: certification.POST('/updateCertTaxNumberInfo', params),
  }
}

//认证抵扣2.0 保存税号信息
export function saveTaxNumberInfoQXY(params) {
  return {
    type: key.SAVE_TAX_NUMBER_INFOQXY,
    payload: certification.POST('/saveTaxNumberInfoQXY', params),
  }
}

//认证抵扣2.0 同步认证
export function certTaxNumberInfoQXY(taxNumber) {
  return {
    type: key.SAVE_CER_TAXNUMBER_INFOQXY,
    payload: certification.POST('/certTaxNumQXY', taxNumber),
  }
}
//认证抵扣2.0 获取购买验证抬头的个数与时间
export function getAuthCodeInfosQXY() {
  return {
    type: key.GET_AUTH_CODE_INFOSQXY,
    payload: certification.GET('/getAuthCodeInfosQXY'),
  }
}

//发票认证 保存税号信息
export function saveTaxNumberInfo(params) {
  return {
    type: key.SAVE_TAX_NUMBER_INFO,
    payload: certification.POST('/saveTaxNumberInfo', params),
  }
}

//发票认证 同步认证
export function certTaxNumberInfo(taxNumber) {
  return {
    type: key.SAVE_CER_TAXNUMBER_INFO,
    payload: certification.POST('/certTaxNumberInfo', taxNumber),
  }
}

//发票认证 保存用户（管理员）
export function saveAdminaistrators(staffId) {
  return {
    type: key.SAVE_ADMINAISTRTORS,
    payload: certification.POST('/saveAdminaistrators', staffId),
  }
}

//获取购买验证抬头的个数与时间
export function getAuthCodeInfos() {
  return {
    type: key.GET_AUTH_CODE_INFOS,
    payload: certification.GET('/getAuthCodeInfos'),
  }
}

export function getInvoiceCertPlatformUrl() {
  return {
    type: key.GET_INVOICE_CERTPLATFORM_URL,
    payload: certification.GET('/openInvoiceCertPlatform'),
  }
}
export function setTravelManagementConfig(params) {
  return {
    type: key.SET_TREVELMANAGEMENT_CONFIG,
    payload: travelManagement.PUT('/setTravelManagementConfig', params),
  }
}

export function getTravelManagementConfig(type) {
  return {
    type: key.GET_TREVELMANAGEMENT_CONFIG,
    payload: travelManagement.GET('/getTravelManagementConfig', type),
  }
}

export function syncPlatform(params) {
  return {
    type: key.AYNC_PLATFORM,
    payload: synTravelOrder.PUT(`/synTravelOrder`, params),
  }
}
// 钉钉待办设置
export function dingtalkGetCharge() {
  return dingtalk.GET('/getCharge')
}

export function dingtalkSetCharge(params) {
  return dingtalk.POST('/setCharge', params)
}

export function didiDownPost(params) {
  return didiDown.POST('/ids', params)
}

export function aliTripGetConfig() {
  return syncAliTrip.GET('/authorize')
}
export function aliTripLiftConfig() {
  return syncAliTrip.PUT('/authorize')
}
export function aliTripUpdateConfig(params) {
  return syncAliTrip.POST('/authorize', params)
}

// 电子档案
export function staffsMe() {
  return staffs.GET('/me')
}

export function eaJwt(data) {
  return gaeaJwt.POST('', {
    data,
  })
}

export function getUrl() {
  return redirectUrl.GET()
}

export function validateCodeBindNbbank(data) {
  return nbbank.POST('/openPay/validateCode', data)
}

export function syncStaff(params) {
  return nbbank.GET('/contrast/syncStaff', params)
}
export function queryStaff(params) {
  return nbbank.POST('/contrast/query', params)
}
export function updateStaff(params) {
  return nbbank.POST('/contrast/updateContrast', params)
}
export function isBinding(params) {
  return nbbank.GET('/openPay/isBinding', params)
}

export function PostTravelManagement(params) {
  return {
    type: key.ADD_CUSTOMTRAVELTYPE,
    payload: travelManagement.POST('/customTravelType', params),
  }
}
export function GetTravelManagement(params) {
  return {
    type: key.GET_CUSTOMTRAVELTYPE,
    payload: travelManagement.GET('/customTravelType', params),
  }
}
export function UpdateTravelManagementActive(params) {
  return {
    type: key.UPDATE_CUSTOMTRAVELTYPEACTIVE,
    payload: travelManagement.PUT('/customTravelType/$id', params, params),
  }
}
export function getEntityList(params) {
  return {
    type: key.GET_ENTITY_LIST,
    payload: datalink.POST('/entity/platformId/$id', params),
  }
}

export function getDimensionsIds(params) {
  return {
    type: key.GET_DIMENSIONSIDS,
    payload: dimensions.GET(`/[${params.ids}]`, {}),
  }
}

export function getAllAuthStaff(params) {
  const join = {
    join$1: `staffId,staffId,/v1/organization/staffs?join=defaultDepartment,defaultDepartment,/v1/organization/departments`,
  }
  return {
    type: key.GET_AFP_ALL_AUTH,
    payload: afpAction.POST('/getAllAuthStaff', params, join),
  }
}

export function getAuthByPowerCode(params) {
  const { type, staffIds, ...others } = params
  return type === 'unOpen'
    ? afpAction.POST('/getToBeAuthorized', others).then((res) => {
        const items = res.items.map((item) => {
          return { ...item, disabled: !item?.cellphone?.length }
        })
        return { ...res, items }
      })
    : afpAction.POST('/getAllBound', others).then((res) => {
        const items = res.items.map((item) => {
          return { ...item, disabled: item.bindType === 'MANUAL_BIND' }
        })
        return { ...res, items }
      })
}

export function batchAuthorize(params) {
  return afpAction.POST('/batchAuthorize', params)
}

export function authAll(params) {
  return afpAction.POST('/fullAuth', params)
}

export function getCountAuthNum() {
  return afpAction.POST('/countAuthNum')
}

export function autoAdd(params) {
  return afpAction.PUT('/autoAdd/$auto', params)
}

export function getPowerByCode(code) {
  return powers.GET('/detail/$code', { code })
}

// 保存并启用邮件
export function actionMailConfig(params) {
  return {
    type: key.ACTION_MAIL_CONFIG,
    payload: mail.POST(`/save`, params),
  }
}
// 发送预览邮件
export function sendMailTest(params) {
  return {
    type: key.SEND_MAIL_TEST,
    payload: mail.POST(`/sendTest`, params),
  }
}
// 初始化获取邮件
export function getMailConfig(params) {
  return {
    type: key.GET_MAIL_CONFIG,
    payload: mail.GET(`/query/${params.templateId}`),
  }
}
// 停用邮件
export function freezeMailConfig(params) {
  return {
    type: key.FREEZE_MAIL_CONFIG,
    payload: mail.GET(`/enabled/${params.templateId}`),
  }
}
// 保存邮件
export function saveMailConfig(params) {
  return {
    type: key.SAVE_MAIL_CONFIG,
    payload: mail.POST(`/saveTo`, params),
  }
}
// 查询已有的配置规则
export function getAutoCreateRulesList() {
  const params = { limit: { start: 0, count: 2999 } }
  return autoCreateConfig.POST('/list', params)
}
// 禁用规则
export function prohibitRule(id) {
  return autoCreateConfig.PUT(`/$id/disable`, { id })
}
// 启用规则
export function enableRule(id) {
  return autoCreateConfig.PUT(`/$id/restore`, { id })
}
// 保存自动创建规则
export function saveAutoCreateRules(params) {
  return autoCreateConfig.PUT('/saveOrUpdate', params)
}
// 查询作废规则列表
export function getDocumentVoidList (data) {
  return {
    type: key.GET_DOCUMENT_VOID_RULES,
    payload: documentVoid.POST('/list', data)
  }
}
// 删除作废规则
export function deleteDocumentVoid (id) {
  return documentVoid.PUT('/$id/delete', { id })
}
// 新增作废规则
export function saveDocumentVoidRules (data) {
  return documentVoid.PUT('/saveOrUpdate', data)
}

export function getSubsidyConfig() {
  return subsidyAction.GET('/list')
}
export function getSubsidyConfigById(id) {
  return subsidyAction.GET('/$id', { id })
}

export function saveSubsidyConfig(params) {
  const { subsidyConfigId } = params
  return subsidyConfigId
    ? subsidyAction.PUT('/updateSubsidyConfig', params)
    : subsidyAction.POST('/addSubsidyConfig', params)
}

export function saveSurplusSubsidyConfig(params) {
  const { subsidyConfigId } = params
  return subsidyConfigId
    ? subsidyAction.PUT('/updateSurplusSubsidyConfig', params)
    : subsidyAction.POST('/addSurplusSubsidyConfig', params)
}

export function getSubsidyEnable(params) {
  return subsidyAction.POST('/enable', params)
}

//获取费用类型
export function getActiveFeeTypes() {
  return {
    type: key.GET_ACTIVE_FEETYPE_LIST,
    payload: feeTypeRes.GET('/tree/active'),
  }
}


/**
 * @return {Promise<Array<import('@ekuaibao/ekuaibao_types').FeeTypeIF>>}
 */
export async function getActiveFeeTypesData() {
  const res = await feeTypeRes.GET('/tree/active')

  return res?.items ?? []
}

//获取行程类型
export function getActiveTripTypes() {
  let query = new QuerySelect().filterBy('active==true').orderBy('createTime', 'ASC').value()
  return {
    type: key.GET_ACTIVE_TRIPTYPE_LIST,
    payload: tripTypes.POST('/search', query),
  }
}
//获取所有行程类型字段
export function getTripTemplateById(id, done) {
  return {
    type: key.GET_TRIPTYPE_TEMPLATE,
    payload: specificationVersions.POST(`/getVersionedAllByIds`, id ),
    done,
  }
}
//获取所有费用类型字段
export function getFeeTemplateById(id, done) {
  return {
    type: key.GET_FEETYPE_TEMPLATE,
    payload: specifications.POST(`/getFeeTypeAllByIds`, id ),
    done,
  }
}

//新建和保存单据联查规则
export function documentQueryRuleSave(params) {
  return {
    type: key.DOCUMENT_QUERY_RULE_SAVE,
    payload: doucmentQuery.PUT('/saveOrUpdate', params),
  }
}

//获取单据联查规则列表
export function documentQueryRuleList(params) {
  return {
    type: key.DOCUMENT_QUERY_RULE_LIST,
    payload: doucmentQuery.POST('/list', params),
  }
}

//禁启用单据联查规则
export function documentQueryRuleDisable(id) {
  return {
    type: key.DOCUMENT_QUERY_RULE_DISABLE,
    payload: doucmentQuery.PUT('/$id/disable', { id }),
  }
}

export function documentQueryRuleRestore(id) {
  return {
    type: key.DOCUMENT_QUERY_RULE_RESTORE,
    payload: doucmentQuery.PUT('/$id/restore', { id }),
  }
}

//新建和保存费用明细自动生成规则
export function autoGenerationFeeDetailSave(params) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL_SAVE,
    payload: autoGenerationFeeDetail.PUT('/saveOrUpdate', params),
  }
}

//获取费用明细自动生成规则列表
export function autoGenerationFeeDetailList(params) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL_LIST,
    payload: autoGenerationFeeDetail.POST('/list', params),
  }
}
//禁启用费用明细生成规则
export function autoGenerationFeeDetailDisable(id) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL_DISABLE,
    payload: autoGenerationFeeDetail.PUT('/$id/disable', { id }),
  }
}
export function autoGenerationFeeDetailRestore(id) {
  return {
    type: key.AUTO_GENERATION_FEE_DETAIL_RESTORE,
    payload: autoGenerationFeeDetail.PUT('/$id/restore', { id }),
  }
}
//获取自定义档案树
export function getDimensionTree(param) {
  const { withVisibility = true, ...others } = param
  return {
    type: key.GET_DIMENSION_TREE,
    payload: referables.GET(`/$name`, { withVisibility, ...others }),
  }
}

export function getAssociationList() {
  return association.GET('/list')
}

export function getSpecificationList() {
  return getSpecificationListByType.GET('/$type', { type: 'requisition' })
}

export function saveAssociation(params) {
  const { id } = params
  return id ? association.PUT('/update', params) : association.POST('/save', params)
}

export function localConfigSelect() {
  return {
    type: key.LOCAL_CONFIG_SELECT,
    payload: localConfig.GET('/select', {}),
  }
}

export function localConfigUpdate(params) {
  return {
    type: key.LOCAL_CONFIG_UPDATE,
    payload: localConfig.POST('/update', params),
  }
}

export function layoutConfigSearch(params) {
  return {
    type: key.POST_LAYOUT_CONFIG_SEARCH,
    payload: layoutConfig.POST('/search', params),
  }
}

export function layoutConfigSearchOne(params) {
  return {
    type: key.POST_LAYOUT_CONFIG_SEARCH_ONE,
    payload: layoutConfig.GET(`/$id`, params),
  }
}

export function getStaffListByIds(params) {
  const { ids } = params
  return {
    type: key.GET_STAFF_LIST_BY_IDS,
    payload: staffRes.GET(`/${ids}`, params),
  }
}

export function layoutConfigSave(params) {
  return {
    type: key.POST_LAYOUT_CONFIG_SAVE,
    payload: layoutConfig.PUT('/setFlowLayoutConfig', params),
  }
}

export function layoutConfigActive(params) {
  return {
    type: key.POST_LAYOUT_CONFIG_ACTIVE,
    payload: layoutConfig.PUT(`/$id?active=${params.active}`, params),
  }
}
/**
 * @description 获取满足虚拟卡条件的业务对象
 * @returns 
 */
export function getEntityListForEBussCard() {
  let join = {
    join: `platformId,platformId,/v2/datalink/platform`,
    join$1: 'parentId,parentId,/v2/datalink/entity'
  }
  return {
    type: key.GET_ENTITY_LIST_FOR_EBUSS_CARD,
    payload: datalink.GET('/entity/autoExpense/getEBussCardEntityList', { ...join })
  }
}

/**
 * @description 虚拟卡详情
 * @returns 
 */
export function getEBussCardInfo () {
  return eBussCardInfo.GET('')
}

/**
 * @description 新增修改虚拟卡
 * @param {*} params 
 * @returns 
 */
export function submitEBussCardInfo(params) {
  const { id } = params
  return id ? eBussCardInfo.PUT('', params) : eBussCardInfo.POST('', params)
}

export function searchExtensionCenterConfig(params) {
  return {
    type: key.SEARCH_EXTENSION_CENTER_CONFIG,
    payload: extensionCenterConfigRes.POST(`/search`, params),
  }
}

export function saveExtensionCenterConfig(params) {
  return {
    type: key.POST_EXTENSION_CENTER_CONFIG,
    payload: extensionCenterConfigRes.PUT(`/save`, params),
  }
}

// 设置小组件配置
export function setWidgetConfig(params) {
  return widgetConfig
    .POST('/', params)
    .catch((err) => showMessage.error(i18n.get(err.msg || err.errorMessage || '接口异常')))
}
// 获取小组件配置
export function getWidgetConfig() {
  return widgetConfig
    .GET('/', {})
    .catch((err) => showMessage.error(i18n.get(err.msg || err.errorMessage || '接口异常')))
}

// 获取吉利商旅配置
export function getGeelySetInfo (id) {
  return {
    type: key.GET_GEELY_TRIP_SET_INFO,
    payload: geelyTripConfig.GET('/$id/binding', { id })
  }
}
// 绑定吉利商旅
export function putGeelyBind (data) {
  return geelyTripConfig.PUT('/$id/binding', data)
}
// 解除绑定吉利商旅
export function deleteGeelyBind (id) {
  return geelyTripConfig.DELETE('/$id/binding', { id })
}
// 员工同步配置
export function syncConfig (data) {
  return geelyTripConfig.PUT('/$id/binding/staff', data)
}
// 全量同步人员
export function syncAllPersion (id) {
  return geelyTripConfig.PUT('/$id/staff/syncAll', { id })
}

// 选择人员同步
export function syncSelectPersion (data) {
  return geelyTripConfig.PUT('/$id/staff/sync', data)
}

// 当前同步数量查询
export function getSyncResultValue (id) {
  return {
    type: key.GET_GEELY_TRIP_SYNC_RESULT,
    payload: geelyTripConfig.GET('/$id/staff/syncResult', { id })
  }
}

// 查询吉利商旅同步日志列表
export function getGeelyTripSyncList (params) {
  return {
    type: key.GET_GEELY_TRIP_SYNC_LIST,
    payload: geelyTripConfig.GET('/$id/staff/binding/list', params)
  }
}

// 编辑吉利商旅对账单同步配置
export function setGeelyApiSyncConfig (params) {
  const { thirdPartyName, formValue } = params
  return {
    type: key.SET_GEELY_API_SYNC_CONFIG,
    payload: geelyTripConfig.POST('/$thirdPartyName/switch',{thirdPartyName, ...formValue})
  }
}

// 查询吉利商旅对账单同步配置
export function getGeelyApiSyncConfig (params) {
  const { thirdPartyName } = params
  return geelyTripConfig.GET('/$thirdPartyName/switch',{thirdPartyName})  
}
// KA-烟草行业对账结算 发送数据
export function departementChekckConfig (id, data) {
  return departmentsCheck.POST('', data, { corpId: id })
}

// KA-烟草行业对账结算 获取数据
export function getDepartementChekckConfig (id) {
  return departmentsCheck.GET('', { corpId: id })
}

// EAI配置查询
export function getEAIwebConfig() {
  return eaiweb.GET('/config')
}

// EAI配置新增
export function addEAIwebConfig(params) {
  return eaiweb.POST('/config', params)
}

// EAI配置修改
export function updateEAIwebConfig(params) {
  return eaiweb.PUT('/config', params)
}

// 登录EAI
export function loginEAIweb() {
  return eaiweb.POST('')
}

export function getMailSendRuleList(params) {
  return {
    type: key.GET_MAIL_SEND_RULE_LIST,
    payload: mailApprove.POST(`/sendRule/search`, params),
  }
}

export function addMailSendRule(params) {
  return {
    type: key.ADD_MAIL_SEND_RULE,
    payload: mailApprove.POST(`/sendRule`, params),
  }
}

export function editMailSendRule(params) {
  return {
    type: key.EDIT_MAIL_SEND_RULE,
    payload: mailApprove.PUT(`/sendRule/$id`, params),
  }
}

export function getMailSendRuleDetail(params) {
  return {
    type: key.GET_MAIL_SEND_RULE_DETAIL,
    payload: mailApprove.GET(`/sendRule/$id`, params),
  }
}

export function enableMailSendRule(params) {
  return {
    type: key.ENABLE_MAIL_SEND_RULE,
    payload: mailApprove.PUT(`/sendRule/enable/$id/$active`, params, { active: params.active }),
  }
}

export function getEmailAddressList(params) {
  return {
    type: key.GET_EMAIL_ADDRESS_LIST,
    payload: mailApprove.GET(`/sendEmailBox/search`, params),
  }
}

export function sendTestEmail(params) {
  return mailApprove.POST('/sendTestEmail', params)
}

export function getEmailSendCount(params) {
  return {
    type: key.GET_EMAIL_SEND_COUNT,
    payload: mailApprove.GET(`/sendEmailBox/sendCount`, params),
  }
}

export function getSendEmailDetail(params) {
  return {
    type: key.GET_SEND_EMAIL_DETAIL,
    payload: mailApprove.GET('/sendEmailBox/$id', params),
  }
}

export function addSendEmail(params) {
  return {
    type: key.ADD_SEND_EMAIL,
    payload: mailApprove.POST('/sendEmailBox', params),
  }
}

export function saveSendEmail(params) {
  return {
    type: key.ADD_SEND_EMAIL,
    payload: mailApprove.PUT('/sendEmailBox/$id', params),
  }
}

export function deleteSendEmail(params) {
  return {
    type: key.DELETE_SEND_EMAIL,
    payload: mailApprove.DELETE('/sendEmailBox/$id', params),
  }
}
