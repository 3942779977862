import {
  getYeegoBindingInfo,
  getRegistrationInfo,
  getOrderBaseProperties,
  getMappingRelation,
  bindStaffDiDi,
  getDiDiPersonalUrl,
  checkDiDiPersonalAuthorized,
  corporationBinding,
  getTravelManagementConfig,
  getTripDataLink,
  GetTravelManagement,
  getEntityList,
  getDimensionsIds,
  getLdapConfig,
  setLdapConfig,
  getSynStatus,
  getLdapSynStaffList,
  syncStaffByStaffIds,
  ldapSyncStaff,
  getLdapLogs,
  getActiveFeeTypes,
  getActiveTripTypes,
  localConfigSelect,
  localConfigUpdate,
  getEntityListForEBussCard
} from './tpp.action'
import { app as api } from '@ekuaibao/whispered'
import { checkThirdPartyAuth } from './didi/didi-utils'
import loadable from '@loadable/component'

// @i18n-ignore-all
export default [
  {
    id: '@tpp-v2',
    path: '/order-manage-center',
    ref: '/',
    onload: () => import('./yeego/OrderManagerCenter'),
    dependencies: ['@auth-check'],
    reducer: () => import('./tpp.reducer.js'),

    'get:yeego:bindingInfo' () {
      return api.dispatch(getYeegoBindingInfo())
    },

    'get:registrationInfo' () {
      return api.dispatch(getRegistrationInfo())
    },

    'get:orderBaseProperties' () {
      return api.dispatch(getOrderBaseProperties()).then((resp) => {
        return resp.items
      })
    },

    'get:mappingRelation' () {
      return api.dispatch(getMappingRelation())
    },

    'retry:didi' () {
      return api.dispatch(bindStaffDiDi({ platformId: 'DIDI' }))
    },

    'check:thirdParty:auth' (params) {
      return checkThirdPartyAuth(params)
    },

    // 'import:corporationBinding': () => corporationBinding,
    'import:corporationBinding' () {
      return api.dispatch(corporationBinding())
    },

    'get:didi:personal:url' (param) {
      return getDiDiPersonalUrl(param)
    },

    'check:didi:personal:authorized' () {
      return api.dispatch(checkDiDiPersonalAuthorized())
    },
    'get:travelManagementConfig' (params) {
      return api.dispatch(getTravelManagementConfig(params))
    },

    'get:tripDataLink' (params) {
      return api.dispatch(getTripDataLink(params))
    },
    'get:tripDataLinkEntityList' (params) {
      return api.dispatch(getTripDataLink(params)).then((res) => {
        if (res?.items?.length > 0) {
          let id = res?.items[0]?.id
          return api
            .dispatch(
              getEntityList({
                id,
              }),
            )
            .then((data) => {
              return data
            })
        }
      })
    },
    'get:getTravelManagement' () {
      return api.dispatch(GetTravelManagement())
    },
    'get:getDimensionsIds' (params) {
      return api.dispatch(getDimensionsIds(params))
    },
    'get:ldap:config' () {
      return api.dispatch(getLdapConfig())
    },
    'set:ldap:config' (params) {
      return api.dispatch(setLdapConfig(params))
    },
    'get:ldap:get:sync:status' () {
      return api.dispatch(getSynStatus())
    },
    'get:ldap:get:sync:staff:list' (value) {
      return api.dispatch(getLdapSynStaffList(value))
    },
    'ldap:sync:staff:by:staffIds' (staffIds) {
      return api.dispatch(syncStaffByStaffIds(staffIds))
    },
    'set:ldap:sync:staff' () {
      return api.dispatch(ldapSyncStaff())
    },
    'get:ldap:logs' (params) {
      return api.dispatch(getLdapLogs(params))
    },
    'get:activeFeeTypes'() {
      return api.dispatch(getActiveFeeTypes())
    },
    'get:activeTripTypes'() {
      return api.dispatch(getActiveTripTypes())
    },
    'get:localConfigSelect'() {
      return api.dispatch(localConfigSelect())
    },
    'set:localConfigUpdate'(params) {
      return api.dispatch(localConfigUpdate(params))
    },
    'get:entity:list:for:ebuss:card'() {
      return api.dispatch(getEntityListForEBussCard())
    },
  },
  {
    point: '@@menus',
    onload: () => {
      return [
        {
          id: 'order-manage-center',
          pId: 'enterprise-manage',
          powers: ['YEEGO'],
          permissions: ['SYS_ADMIN'],
          weight: 101,
          label: '订购管理',
          href: '/order-manage-center',
        },
      ]
    },
  },
  {
    point: '@@components',
    namespace: '@tpp-v2',
    onload: () => [
      { key: 'DiDiEnterView', component: () => import('./didi/DiDiEnterView') },
      { key: 'DiDiServiceCardView', component: () => import('./didi/DiDiServiceCardView') },
      { key: 'DiDiServiceCardViewNew', component: () => import('./didi/DiDiServiceCardViewNew') },
      { key: 'TripDataLink', component: () => import('./tripDataLink/index') },
      { key: 'DiDiPersonal', component: () => import('./didi-personal/ConfigView') },
      {
        key: 'InvoiceCertification',
        component: () => import('./InvoiceCertification/InvoiceCertificationView')
      },
      {
        key: 'ImportTaxDedcutionManagement',
        component: () => import('./InvoiceCertification/ImportTaxDedcutionView')
      },
      { key: 'DingtalkTodos', component: () => import('./DingtalkTodos') },
      { key: 'AutoCalcRuleList', component: () => import('./auto-calc/auto-calc-rule-list-view') },
      { key: 'AliTrip', component: () => import('./aliTrip/index') },
      { key: 'ElectronicArchives', component: () => import('./ElectronicArchives') },
      { key: 'NbbankPayment', component: () => import('./nbbank/page/index') },
      { key: 'TmcMall', component: () => import('./TmcMall/index') },
      { key: 'LDAPConfig', component: () => import('./ldap/LDAPSettingView') },
      { key: 'AiFaPiaoView', component: () => import('./aifapiao/AiFaPiaoView') },
      { key: 'MailApproval', component: () => import('./MailApproval/index-new') },
      { key: 'ElectronicFunds', component: () => import('./ElectronicFunds/index') },
      { key: 'Delegation', component: () => import('./Delegation/index') },
      { key: 'SubsidyManage', component: () => import('./SubsidyManage/index') },
      { key: 'DocumentJointQuery', component: () => import('./DocumentJointQuery/index') },
      { key: 'GroupPlugin', component: () => import('./GroupPlugin/index') }, // 钉钉群组件
      { key: 'LocalConfig', component: () => import('./LocalConfig/index') },
      { key: 'AutogenerateFeeDetails', component: () => import('./AutogenerateFeeDetails/index') },
      { key: 'BillInfoDetailLayout', component: () => import('./BillInfoDetailLayout/index') },
      { key: 'EBussCard', component: () => import('./EBussCard/index') },
      { key: 'Express', component: () => import('./Express/index') },
      { key: 'WidgetConfig', component: () => import('./WidgetConfig2/widgetRule2List').then(v => v.WidgetRule2ListPage) },
      { key: 'TMSConfig', component: () => import('./tmspay/page/tms-config')},
      { key: 'GeelyTrip', component: () => import('./GeelyTrip/index') },
      { key: 'TCTrip', component: () => import('./TCTrip/index') },
      { key: 'ReconciliationSettlement', component: () => import('./ReconciliationSettlement/index') },
      { key: 'EAIConfig', component: () => import('./EAI_Web/EAIconfig') },
      { key: 'DocumentVoid', component: () => import('./documentVoid/index') }
    ],
  },
  {
    path: '/hose-mall-manage',
    ref: '/',
    onload: () => import('./tmc/TmcManage'),
  },
  {
    path: '/hose-mall-manage/:path1',
    ref: '/',
    onload: () => import('./tmc/TmcManage'),
  },
  {
    path: '/hose-mall-manage/:path1/:path2',
    ref: '/',
    onload: () => import('./tmc/TmcManage'),
  },
  {
    path: '/hose-mall-index',
    ref: '/',
    onload: () => import('./tmc/TmcIndex'),
  },
  {
    path: '/hose-mall-index/:pageType',
    ref: '/',
    onload: () => import('./tmc/TmcIndex'),
  },
  {
    path: '/electronic-archives',
    ref: '/',
    onload: () => import('./ElectronicArchives'),
  },
  {
    path: '/eai-web',
    ref: '/',
    onload: () => import('./EAI_Web'),
  },
  {
    path: '/corporate-wallet/:subPath',
    ref: '/',
    onload: () => import('./CorporateWallet'),
  },
  {
    point: '@@layers',
    prefix: '@tpp-v2',
    onload: () => import('./layer'),
  },
  {
    resource: '@third-party-manage',
    value: {
      ['didi/order-data-grid/DataGridWrapper']: loadable(
        () => import('./didi/order-data-grid/DataGridWrapper'),
      ),
      ['elements/form/external-person-inputComp']: loadable(
        () => import('./elements/form/external-person-inputComp'),
      ),
      ['elements/form/member-selectComp']: loadable(
        () => import('./elements/form/member-selectComp'),
      ),
      ['elements/form/TimePeriodSelectCopm']: loadable(
        () => import('./elements/form/TimePeriodSelectCopm'),
      ),
    },
  },
]