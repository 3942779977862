import { app as api } from '@ekuaibao/whispered'
const ID = 'DIDI'
function getDiDiStatus(list = []) {
  let item = list.find(o => o.id === ID) || {}
  let { corporationBinding = [], staffBinding = [] } = item
  let corporationStatus = corporationBinding.find(v => v.platformId === ID) || {}
  let staffStatus = staffBinding.find(v => v.platformId === ID) || {}
  return { staffStatus, corporationStatus }
}
function fnCheckBindError(staffStatus = {}) {
  if (!staffStatus.isBinding && staffStatus.bindingError) {
    return api.open(`@tpp-v2:DisplayErrorModal`, { staffStatus })
  }
  return Promise.resolve()
}
function fnCheckStaffStatus(staffStatus = {}) {
  if (!staffStatus.isBinding && (!staffStatus.bindingError || staffStatus.bindingError === 'ACCOUNTUNKNOWN')) {
    return api.open('@tpp-v2:DiDiBindUserModal').then(v =>
      api.invokeService('@tpp-v2:get:registrationInfo').then(data => {
        let { staffStatus } = getDiDiStatus(data)
        return fnCheckBindError(staffStatus)
      })
    )
  }

  if (!staffStatus.isBinding && staffStatus.bindingError) {
    return fnCheckBindError(staffStatus)
  }

  return Promise.resolve()
}

function fnCheckCorporationStatus(corporationStatus = {}) {
  if (!corporationStatus.isBinding) {
    return api.open('@tpp-v2:RegisterDiDiModal', { bindingStatus: corporationStatus })
  }
  return Promise.resolve()
}

export function checkThirdPartyAuth(item) {
  let { corporationBinding = [], staffBinding = [] } = item
  if (item.id === ID) {
    let corporationStatus = corporationBinding.find(v => v.platformId === ID)
    let staffStatus = staffBinding.find(v => v.platformId === ID)
    return fnCheckCorporationStatus(corporationStatus).then(_ => {
      return fnCheckStaffStatus(staffStatus)
    })
  }

  return Promise.resolve()
}
