/**
 * Created by ZhangXingBo.
 */
export const ID = '@tpp-v2'

export default {
  ID: ID,
  GET_YEEGO_BINDING_INFO: `${ID}/GET_YEEGO_BINDING_INFO`,
  BIND_CORP: `${ID}/BIND_CORP`,
  GET_UNKNOWN_STAFFS: `${ID}/GET_UNKNOWN_STAFFS`,
  CHANGE_STATUS: `${ID}/CHANGE_STATUS`,
  GET_DIDI_SYCH_STATE: `${ID}/GET_DIDI_SYCH_STATE`,
  SYCH_DIDI_STATE: `${ID}/SYCH_DIDI_STATE`,
  GET_SYCH_STAFF_RESULT: `${ID}/GET_SYCH_STAFF_RESULT`,

  GET_AUTH_STATUS: `${ID}/GET_AUTH_STATUS`,
  SET_DIDI_AUTH_STATUS: `${ID}/SET_DIDI_AUTH_STATUS`,

  GET_ORDER_BASE_PROPERTIES: `${ID}/GET_ORDER_BASE_PROPERTIES`,

  GET_REGISTRATION_INFO: `${ID}/GET_REGISTRATION_INFO`,
  GET_DIDI_REGISTRATION_INFO: `${ID}/GET_DIDI_REGISTRATION_INFO`,
  GET_AUTH_URL: `${ID}/GET_AUTH_URL`,
  GET_AUTH_URL_NEW: `${ID}/GET_AUTH_URL_NEW`,
  SEARCH_DIDI_ORDERS_DATA: `${ID}/SEARCH_DIDI_ORDERS_DATA`,
  SEND_MESSAGE: `${ID}/SEND_MESSAGE`,
  GET_DIDI_CAPTCHA: `${ID}/GET_DIDI_CAPTCHA`,
  BIND_STAFF_DIDI: `${ID}/BIND_STAFF_DIDI`,
  SWITCH_THIRD_PARTY: `${ID}/SWITCH_THIRD_PARTY`,
  GET_DISPLAY_FIELDS: `${ID}/GET_DISPLAY_FIELDS`,
  REMIND_REIMBURSE: `${ID}/REMIND_REIMBURSE`,
  GET_MAPPING_RELATION: `${ID}/GET_MAPPING_RELATION`,
  GET_ALL_ORDER_IDS: `${ID}/GET_ALL_ORDER_IDS`,
  GET_WHITELISTE_STAFFS: `${ID}/GET_WHITELISTE_STAFFS`,
  SAVE_WHITELISTE_STAFFS: `${ID}/SAVE_WHITELISTE_STAFFS`,
  SAVE_CONTROLCONDITION: `${ID}/SAVE_CONTROLCONDITION`,
  GET_CORPORATION_BINDING: `${ID}/GET_CORPORATION_BINDING`,
  GET_TRIP_DATALINK: `${ID}/GET_TRIP_DATALINK`,
  UPDATE_TRIP_DATALINK: `${ID}/UPDATE_TRIP_DATALINK`,

  LDAP_GET_LOGIN_CONFIG: `${ID}/LDAP_GET_LOGIN_CONFIG`,
  LDAP_UPDATE_LOGIN_CONFIG: `${ID}/LDAP_UPDATE_LOGIN_CONFIG`,
  LDAP_GET_SYN_STATUS: `${ID}/LDAP_GET_SYN_STATUS`,
  LDAP_GET_SYN_STAFF_LIST: `${ID}/LDAP_GET_SYN_STAFF_LIST`,
  LDAP_SYNC_STAFF_BY_STAFF_ID: `${ID}/LDAP_SYNC_STAFF_BY_STAFF_ID`,
  DIDI_SYNC_STAFF_BY_STAFF_ID: `${ID}/DIDI_SYNC_STAFF_BY_STAFF_ID`,
  LDAP_SYNC_STAFF: `${ID}/LDAP_SYNC_STAFF`,
  LDAP_GET_LOGS: `${ID}/LDAP_GET_LOGS`,

  GET_PASSENGER: `${ID}/GET_PASSENGER`,
  AYNC_PASSENGER: `${ID}/AYNC_PASSENGER`,
  GET_BUYER: `${ID}/GET_BUYER`,
  AYNC_BUYER: `${ID}/AYNC_BUYER`,
  DIDI_PERSONAL_IS_AUTHORIZED: `${ID}/DIDI_PERSONAL_IS_AUTHORIZED`,

  GET_CERTIFICATION_CONFIG: `${ID}/GET_CERTIFICATION_CONFIG`,
  UPDATE_CERT_TAXNUMBER_INFO: `${ID}/UPDATE_CERT_TAXNUMBER_INFO`,
  SAVE_TAX_NUMBER_INFOQXY: `${ID}/SAVE_TAX_NUMBER_INFOQXY`,
  SAVE_CER_TAXNUMBER_INFOQXY: `${ID}/SAVE_CER_TAXNUMBER_INFOQXY`,
  SAVE_TAX_NUMBER_INFO: `${ID}/SAVE_TAX_NUMBER_INFO`,
  SAVE_CER_TAXNUMBER_INFO: `${ID}/SAVE_CER_TAXNUMBER_INFO`,
  SAVE_ADMINAISTRTORS: `${ID}/SAVE_ADMINAISTRTORS`,
  GET_AUTH_CODE_INFOS: `${ID}/GET_AUTH_CODE_INFOS`,
  GET_AUTH_CODE_INFOSQXY: `${ID}/GET_AUTH_CODE_INFOSQXY`,
  GET_INVOICE_CERTPLATFORM_URL: `${ID}/GET_INVOICE_CERTPLATFORM_URL`,

  SET_TREVELMANAGEMENT_CONFIG: `${ID}/SET_TREVELMANAGEMENT_CONFIG`,
  GET_TREVELMANAGEMENT_CONFIG: `${ID}/GET_TREVELMANAGEMENT_CONFIG`,
  AYNC_PLATFORM: `${ID}/AYNC_PLATFORM`,
  ADD_CUSTOMTRAVELTYPE: `${ID}/ADD_CUSTOMTRAVELTYPE`,
  GET_CUSTOMTRAVELTYPE: `${ID}/GET_CUSTOMTRAVELTYPE`,
  UPDATE_CUSTOMTRAVELTYPEACTIVE: `${ID}/UPDATE_CUSTOMTRAVELTYPEACTIVE`,
  GET_ENTITY_LIST: `${ID}/GET_ENTITY_LIST`,
  SYNC_DIDI_STAFF: `${ID}/SYNC_DIDI_STAFF`,
  GET_DIDI_STAFF_SYNC_STATUS: `${ID}/GET_DIDI_STAFF_SYNC_STATUS`,
  GET_DIDI_SYNC_LOGS: `${ID}/GET_DIDI_SYNC_LOGS`,
  SYNC_CAR_SYSTEM: `${ID}/SYNC_CAR_SYSTEM`,
  CAR_SYSTEM_FIELD_SETTINGS: `${ID}/CAR_SYSTEM_FIELD_SETTINGS`,
  GET_CAR_SYSTEM_FIELD_SETTINGS: `${ID}/GET_CAR_SYSTEM_FIELD_SETTINGS`,
  GET_DIMENSIONSIDS: `${ID}/GET_DIMENSIONSIDS`,
  GET_AFP_ALL_AUTH: `${ID}/GET_AFP_ALL_AUTH`,
  ACTION_MAIL_CONFIG: `${ID}/ACTION_MAIL_CONFIG`, //开启邮件审批
  GET_MAIL_CONFIG: `${ID}/GET_MAIL_CONFIG`, //获取邮件审批
  SEND_MAIL_TEST: `${ID}/SEND_MAIL_TEST`, //发送邮件审批
  FREEZE_MAIL_CONFIG: `${ID}/FREEZE_MAIL_CONFIG`, //停用邮件审批
  SAVE_MAIL_CONFIG: `${ID}/SAVE_MAIL_CONFIG`, //保存邮件审批
  GET_ELEC_ENTITY_LIST: `${ID}/GET_ELEC_ENTITY_LIST`, // 所有经费本
  UPDATE_ELEC_ENTITY_LIST: `${ID}/UPDATE_ELEC_ENTITY_LIST`, // 更新经费本
  SELECTED_ELEC_ENTITY_LIST: `${ID}/SELECTED_ELEC_ENTITY_LIST`, // 已选经费本
  DELEGATE_RANGE_OBJ: `${ID}/DELEGATE_RANGE_OBJ`, //委托授权可见范围查询
  DELEGATE_RANGE_EDIT: `${ID}/DELEGATE_RANGE_EDIT`,//委托授权可见范围保存
  DELEGATE_RULE_LIST: `${ID}/DELEGATE_RULE_LIST`,//委托授权规则列表
  DELEGATE_RULE_EDIT: `${ID}/DELEGATE_RULE_EDIT`,//委托授权规则保存
  DELEGATE_RULE_MODIFIY: `${ID}/DELEGATE_RULE_MODIFIY`,//委托授权规则修改
  DELEGATE_RULE_DEL: `${ID}/DELEGATE_RULE_DEL`,//委托授权规则删除
  SET_DELEGATE_CONFIG: `${ID}/SET_DELEGATE_CONFIG`,
  MODIFY_DELEGATE_CONFIG: `${ID}/MODIFY_DELEGATE_CONFIG`,
  GET_DELEGATE_LIST_BY_TYPE: `${ID}/GET_DELEGATE_LIST_BY_TYPE`,
  DELETE_DELAGATE_ITEM: `${ID}/DELETE_DELAGATE_ITEM`,

  SET_DELEGATE_CONFIG_BATCH: `${ID}/SET_DELEGATE_CONFIG_BATCH`,
  MODIFY_DELEGATE_CONFIG_BATCH: `${ID}/MODIFY_DELEGATE_CONFIG_BATCH`,
  GET_DELEGATE_LIST_BY_TYPE_BATCH: `${ID}/GET_DELEGATE_LIST_BY_TYPE_BATCH`,
  DELETE_DELAGATE_ITEM_BATCH: `${ID}/DELETE_DELAGATE_ITEM_BATCH`,

  SET_REQUISITION_ORDER: `${ID}/SET_REQUISITION_ORDER`,
  GET_REQUISItION_ORDER: `${ID}/GET_REQUISItION_ORDER`,
  DELETE_AIFAPIAO_BINGDING: `${ID}/DELETE_AIFAPIAO_BINGDING`,
  UPDATE_DELEGATE_CONFIG:`${ID}/UPDATE_DELEGATE_CONFIG`,
  GET_DELEGATE_CONFIG:`${ID}/GET_DELEGATE_CONFIG`,
  GET_ACTIVE_FEETYPE_LIST: `${ID}/GET_ACTIVE_FEETYPE_LIST`, 
  GET_ACTIVE_TRIPTYPE_LIST: `${ID}/GET_ACTIVE_TRIPTYPE_LIST`, 
  GET_TRIPTYPE_TEMPLATE: `${ID}/GET_TRIPTYPE_TEMPLATE`, 
  GET_FEETYPE_TEMPLATE: `${ID}/GET_FEETYPE_TEMPLATE`,
  DOCUMENT_QUERY_RULE_SAVE: `${ID}/DOCUMENT_QUERY_RULE_SAVE`,
  DOCUMENT_QUERY_RULE_LIST: `${ID}/DOCUMENT_QUERY_RULE_LIST`,
  DOCUMENT_QUERY_RULE_DISABLE: `${ID}/DOCUMENT_QUERY_RULE_DISABLE`,
  DOCUMENT_QUERY_RULE_RESTORE: `${ID}/DOCUMENT_QUERY_RULE_RESTORE`,
  LOCAL_CONFIG_SELECT: `${ID}/LOCAL_CONFIG_SELECT`,
  LOCAL_CONFIG_UPDATE: `${ID}/LOCAL_CONFIG_UPDATE`,
  AUTO_GENERATION_FEE_DETAIL_SAVE: `${ID}/AUTO_GENERATION_FEE_DETAIL_SAVE`,
  AUTO_GENERATION_FEE_DETAIL_LIST: `${ID}/AUTO_GENERATION_FEE_DETAIL_LIST`,
  AUTO_GENERATION_FEE_DETAIL_DISABLE: `${ID}/AUTO_GENERATION_FEE_DETAIL_DISABLE`,
  AUTO_GENERATION_FEE_DETAIL_RESTORE: `${ID}/AUTO_GENERATION_FEE_DETAIL_RESTORE`, 
  GET_DIMENSION_TREE: `${ID}/GET_DIMENSION_TREE`,
  POST_LAYOUT_CONFIG_SEARCH: `${ID}/POST_LAYOUT_CONFIG_SEARCH`,
  POST_LAYOUT_CONFIG_SEARCH_ONE: `${ID}/POST_LAYOUT_CONFIG_SEARCH_ONE`,
  GET_STAFF_LIST_BY_IDS: `${ID}/GET_STAFF_LIST_BY_IDS`,
  POST_LAYOUT_CONFIG_SAVE: `${ID}/POST_LAYOUT_CONFIG_SAVE`,
  POST_LAYOUT_CONFIG_ACTIVE: `${ID}/POST_LAYOUT_CONFIG_ACTIVE`,
  GET_ENTITY_LIST_FOR_EBUSS_CARD: `${ID}/GET_ENTITY_LIST_FOR_EBUSS_CARD`,
  SEARCH_EXTENSION_CENTER_CONFIG: `${ID}/SEARCH_EXTENSION_CENTER_CONFIG`,
  POST_EXTENSION_CENTER_CONFIG: `${ID}/POST_EXTENSION_CENTER_CONFIG`,
  GET_GEELY_TRIP_SET_INFO: `${ID}/GET_GEELY_TRIP_SET_INFO`,
  GET_GEELY_TRIP_SYNC_RESULT: `${ID}/GET_GEELY_TRIP_SYNC_RESULT`,
  GET_GEELY_TRIP_SYNC_LIST: `${ID}/GET_GEELY_TRIP_SYNC_LIST`,
  SET_GEELY_API_SYNC_CONFIG: `${ID}/SET_GEELY_API_SYNC_CONFIG`,

  GET_MAIL_SEND_RULE_LIST: `${ID}/GET_MAIL_SEND_RULE_LIST`,
  ADD_MAIL_SEND_RULE: `${ID}/ADD_MAIL_SEND_RULE`,
  EDIT_MAIL_SEND_RULE: `${ID}/EDIT_MAIL_SEND_RULE`,
  GET_MAIL_SEND_RULE_DETAIL: `${ID}/GET_MAIL_SEND_RULE_DETAIL`,
  ENABLE_MAIL_SEND_RULE: `${ID}/ENABLE_MAIL_SEND_RULE`,
  GET_EMAIL_ADDRESS_LIST: `${ID}/GET_EMAIL_ADDRESS_LIST`,
  GET_EMAIL_SEND_COUNT: `${ID}/GET_EMAIL_SEND_COUNT`,
  GET_SEND_EMAIL_DETAIL: `${ID}/GET_SEND_EMAIL_DETAIL`,
  ADD_SEND_EMAIL: `${ID}/ADD_SEND_EMAIL`,
  SAVE_SEND_EMAIL: `${ID}/SAVE_SEND_EMAIL`,
  DELETE_SEND_EMAIL: `${ID}/DELETE_SEND_EMAIL`,
  GET_DOCUMENT_VOID_RULES: `${ID}/GET_DOCUMENT_VOID_RULES`,  // 单据作废规则列表
}
